import React from 'react';
import { Modal, Button, Alert } from 'react-bootstrap';

interface ResubmitRequestModalProps {
  show: boolean;
  handleClose: () => void;
  modalMessage: string;
  isLoading: boolean;
  showSuccessMessage: { success: string, error: string };
  handleSubmitRequest: (studentUUID: string, applicationStatus: any, programUUID: string, formID: string, modalMessage: string, currentUserUUID: string) => void;
  formData: any;
  currentUser: any;
}


const ResubmitRequestModal: React.FC<ResubmitRequestModalProps> = ({
  show,
  handleClose,
  modalMessage,
  isLoading,
  showSuccessMessage,
  handleSubmitRequest,
  formData,
  currentUser
}) => (
  <Modal show={show} onHide={handleClose}>
    <Modal.Header closeButton>
      <Modal.Title>Confirmation</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      Are you sure you want to request to {modalMessage}?
      {showSuccessMessage.success && (
        <Alert variant="success" className="mt-3">{showSuccessMessage.success}</Alert>
      )}
      {showSuccessMessage.error && (
        <Alert variant="danger" className="mt-3">{showSuccessMessage.error}</Alert>
      )}
    </Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={handleClose}>Cancel</Button>
      <Button variant="primary" disabled={isLoading} onClick={() => {
    handleSubmitRequest(
      formData.studentUUID,
      formData.applicationStatus,
      formData.programUUID,
      formData.formID,
      modalMessage,
      currentUser?.uuid
    );
  }}>
        Confirm
      </Button>
    </Modal.Footer>
  </Modal>
);

export default ResubmitRequestModal;