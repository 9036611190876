import React, {useState, SetStateAction, Dispatch} from 'react'
import {useMutation, useQueryClient} from 'react-query'
import useAppRequests from '../../Hooks/useAppRequests'
import {parse_payment} from '../../../../../utils/funcHelpers'
import _queryKeys from '../../core/_queryKeys'
import {IPaymentMethods, IPaymentMethod} from '../../core/_models'
import PaymentModal from '.././modal/PaymentModal'
import clsx from 'clsx'
import PaymentMethodModal from '../modal/PaymentMethodModal'
import { useAuth } from '../../../auth'
import { toAbsoluteUrl } from '../../../../../_metronic/helpers'
import { methodType } from '../../../../NewTerra/PayLedger'
import { COUNTRIES_ISO_3166_ALPHA_3 } from '../../../../../utils'
import PaymentMethodInputsV3 from '../../../../NewTerra/PaymentMethodInputV3'
import { axiosPrivate } from '../../../../../axiosInstance'
interface Props { 
  paymentData: IPaymentMethods | undefined
  id: string | undefined
  close: any
  isPaymentLoading: boolean
  payment_method: IPaymentMethod | undefined
  setMessage: Dispatch<SetStateAction<object>>
}

type CreditCardInfo = {
  nameOnCard: string;
  cardNumber: string;
  cardExpiryMonth: string;
  cardExpiryYear: string;
  cardCvv: string;
  billingAddress: string;
  billingCity: string;
  billingStateProvince: string;
  billingPostalCode: string;
  billingCountry: string;
};

type BankAccountInfo = {
  nameOnCard: string;
  routingNumber: string;
  accountNumber: string;
  bankType: 'checking' | 'savings';
};

type PaymentType = 'creditCard' | 'bankAccount' | 'debitCard' | '';


const PaymentMethodField = ({
  paymentData,
  id,
  close,
  isPaymentLoading,
  payment_method,
  setMessage,
}: Props) => {
  const queryClient = useQueryClient()
  const {currentUser} = useAuth()
  const [itemId, setItemId] = useState<string | undefined>(undefined)
  const [itemPMethod, setItemPMethod] = useState<{
    modal?: string
    uuid?: string
    item: IPaymentMethod
  } | undefined>(
    undefined
  )
  const [payment, setPayment] = useState<IPaymentMethod>()
  const [paymentType, setPaymentType] = useState<PaymentType>('');
  const [submit, setSubmit] = useState(false) 
  const [primaryPayment, setPrimaryPayment] = useState<IPaymentMethod | null>(null);
  const [selectPMethod, setSelectPMethod] = useState<string>('');
  const [selectedFunding, setSelectedFunding] = useState<string>('');
  const [creditCardInfo, setCreditCardInfo] = useState<CreditCardInfo>({
    nameOnCard: '',
    cardNumber: '',
    cardExpiryMonth: '',
    cardExpiryYear: '',
    cardCvv: '',
    billingAddress: '',
    billingCity: '',
    billingStateProvince: '',
    billingPostalCode: '',
    billingCountry: ''
  });
  const [bankAccountInfo, setBankAccountInfo] = useState<BankAccountInfo>({
    nameOnCard: '',
    routingNumber: '',
    accountNumber: '',
    bankType: 'checking'
  });

  const {setActivePaymentMethodReq, deletePaymentMethodV2} = useAppRequests()

  const {
    isLoading: isActivatePMethodLoading,
    isSuccess: isActivePMethodSuccess,
    isError: isActivePMethodError,
    mutateAsync: activatePMethodByAdminAsync,
  } = useMutation(setActivePaymentMethodReq)

  const {
    isLoading: isDeletePMethodLoading,
    isSuccess: isDeletePMethodSuccess,
    isError: isDeletePMethodError,
    mutateAsync: deletePaymentMethodAsync,
  } = useMutation(deletePaymentMethodV2)

  const handlePaymentMethod = async (apiFunc: any, successMessage: any) => {
    try {
      let idConvertedNum = parseInt(itemPMethod?.item.id || '')
      if (idConvertedNum) {
        const res = await apiFunc({ id: idConvertedNum, card_holder_uuid: currentUser?.uuid })
        if (res) {
          setItemPMethod(undefined)
          setMessage({ statusCode: true, message: successMessage })
        }
      }
      // Refreshing list by using useQueryClient
      await queryClient.prefetchQuery(_queryKeys.getUserPaymentsReq)
    } catch (ex) {
      console.error(ex)
    } finally {
      setItemPMethod(undefined)
    }
  }

  const activatePaymentMethod = async () => {
    return handlePaymentMethod(
      activatePMethodByAdminAsync,
      'Primary card has been set successfully!'
    );
  };
  
  const deletePaymentMethod = async () => {
    return handlePaymentMethod(
      deletePaymentMethodAsync,
      'Primary card has been deleted successfully!'
    );
  };

  let pMethodSaved = undefined as any
  let pMethodSavedACH = undefined as any
  let pMethodSavedCredit = undefined as any
  let pMethodSavedDebit = undefined as any

  if(paymentData?.payment_methods){
    pMethodSaved = (!paymentData?.payment_methods) ? null : paymentData?.payment_methods?.filter((pm) => pm.isActive === true) 
    pMethodSavedCredit = (!paymentData?.payment_methods) ? null : paymentData?.payment_methods?.filter((pm) => pm.paymentType === 'creditCard' && pm.isActive === false)
    pMethodSavedACH = (!paymentData?.payment_methods) ? null : paymentData?.payment_methods?.filter((pm) => pm.paymentType === 'bankAccount' && pm.isActive === false)
    pMethodSavedDebit = (!paymentData?.payment_methods) ? null : paymentData?.payment_methods?.filter((pm) => pm.paymentType === 'debitCard' && pm.isActive === false)
  }

  let ccCount = 0
  let ACHCount = 0
  let dcCount = 0

  if (pMethodSavedCredit) ccCount = pMethodSavedCredit.length
  if (pMethodSavedACH) ACHCount = pMethodSavedACH.length
  if (pMethodSavedDebit) dcCount = pMethodSavedDebit.length

  const handleInputChange = <
  T extends CreditCardInfo | BankAccountInfo
  >(
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    setInfo: React.Dispatch<React.SetStateAction<T>>
  ) => {
    const { name, value } = e.target;
    setInfo((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const handlePMethodSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectPMethod(e.target.value);
    setSelectedFunding(e.target.value);
    setPrimaryPayment(null)
  };

  const handlePrimaryMethodSelect = (e: React.ChangeEvent<HTMLInputElement>, data: any, card?: string) => {
    setSelectPMethod(e.target.value);
    setSelectedFunding(e.target.value);
    setPrimaryPayment({ ...data, card }); 
  };

  const handlePaymentType = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectPMethod('');
    setSelectedFunding('');
    setPaymentType(e.target.id as PaymentType);
  }

  const onSubmit = async (e: any) => {
    e.preventDefault()
    setSubmit(true)
    try {
      const values = {
        card_holder_uuid: currentUser?.uuid || '',
        paymentType: paymentType,
        isActive: false,
        creditCardInfo: {
          ...creditCardInfo,
        },
        bankAccountInfo: {
          ...bankAccountInfo,
        }
      }
      const res = await axiosPrivate.post<IPaymentMethods>(`/auth/payment-method/add`, values)
      if (res) {
        setMessage({
          message: 'Payment method has been added successfully!',
          statusCode: true
        })
      } else {
        setMessage({
          message: 'Payment method Already Exists!',
          statusCode: false
        })
      }
      await queryClient.prefetchQuery(_queryKeys.getUserPaymentsReq)
    } catch (error) {
      console.log(error)
    } finally {
      setSubmit(false)
    }
  }

  console.log(primaryPayment, 'primary', !paymentType && primaryPayment?.card !== 'primary')
  console.log(!paymentType, 'primary')

  return (
    <div className='card-body py-3'>
      <form onSubmit={onSubmit}>
        <div className='d-flex gap-3'>
          <div className="accordion col-md-3 mb-5" id="paymentMethodAccordionWrapper">
            <div className="accordion-item">
              <h2 className="accordion-header card" id="headingPaymentMethod">
                <button
                  className="accordion-button fs-4 collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapsePaymentMethod"
                  aria-expanded="false"
                  aria-controls="collapsePaymentMethod"
                >
                  Primary Payment Method
                </button>
              </h2>
              <div
                id="collapsePaymentMethod"
                className="accordion-collapse collapse"
                aria-labelledby="headingPaymentMethod"
                data-bs-parent="#paymentMethodAccordionWrapper"
              >
                <div className="accordion-body card">
                  {pMethodSaved && pMethodSaved.length > 0 ? (
                    pMethodSaved.map((data: any, idx: number) => {
                      const dataSaveText = parse_payment(data);
                      return (
                        <div className='card p-4' key={idx}>
                        <div className='d-flex justify-content-between'>
                            <label
                              className='form-check-label'
                              htmlFor={`funding_${data?.id}`}
                            >
                              {dataSaveText?.img && (
                                <img
                                  src={toAbsoluteUrl(dataSaveText?.img)}
                                  alt={data.value}
                                  className="h-25px"
                                />
                              )}
                              {dataSaveText?.value}{' '}
                              xx{data?.cardNumber?.replaceAll('x', '')}
                            </label>
                            <input
                              type='radio'
                              className='form-check-input me-2'
                              value={`funding_${data?.id}`}
                              name={'selectPMethod'}
                              id={`funding_${data?.id}`}
                              onChange={(e: any) => handlePrimaryMethodSelect(e, data, 'primary')}
                              />
                          </div>
                        </div>
                      );
                    })
                  ) : (
                  <tr className="alert alert-warning align-items-center">
                    <td colSpan={3} className="text-center">
                      <h5 className="mb-1">No Active Funding</h5>
                    </td>
                  </tr>
                )}
                </div>
              </div>

              {methodType.map((method) => (
                <div className="accordion-item" key={method.type}>
                  <h2 className="accordion-header" id={`heading_${method.type}`}>
                    <button
                      className={`accordion-button fs-4 ${paymentType === method.type ? '' : 'collapsed'}`}
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={`#collapse_${method.type}`}
                      aria-expanded={paymentType === method.type ? 'true' : 'false'}
                      aria-controls={`collapse_${method.type}`}
                      onClick={(e: any) => handlePaymentType(e)}
                      id={method.type}
                    >
                      {method.name}
                    </button>
                  </h2>
                  <div
                    id={`collapse_${method.type}`}
                    className={`accordion-collapse collapse ${paymentType === method.type ? 'show' : ''}`}
                    aria-labelledby={`heading_${method.type}`}
                    data-bs-parent="#paymentMethodAccordion"
                  >
                    <div className="accordion-body">
                      <PaymentMethodInputsV3
                        paymentType={paymentType}
                        selectPMethod={selectPMethod}
                        pMethodSaved={pMethodSaved}
                        pMethodSavedCredit={pMethodSavedCredit}
                        pMethodSavedACH={pMethodSavedACH}
                        pMethodSavedDebit={pMethodSavedDebit}
                        setPrimaryPayment={setPrimaryPayment}
                        ccCount={ccCount}
                        ACHCount={ACHCount}
                        dcCount={dcCount}
                        handlePMethodSelect={handlePMethodSelect}
                        handleInputChange={handleInputChange}
                        creditCardInfo={creditCardInfo}
                        bankAccountInfo={bankAccountInfo}
                        setCreditCardInfo={setCreditCardInfo}
                        setBankAccountInfo={setBankAccountInfo}
                        text={'add'}
                        setItemPMethod={setItemPMethod}
                        handlePrimaryMethodSelect={handlePrimaryMethodSelect}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {primaryPayment && (
            <div className='d-flex' style={{ flexDirection: 'column' }}>
              <div className='d-flex flex-row justify-content-between align-items-center'>
                <h3 className="p-0 m-0">Card Information</h3>
                <button
                  type="button"
                  disabled={true}
                  className={clsx(
                    'btn btn-sm',
                    primaryPayment.isActive
                      ? 'btn-outline-success'
                      : 'btn-outline-secondary'
                  )}
                >
                  <i className="fa-sharp fa-solid fa-circle-check"></i>
                </button>
              </div>
              <div>
                <div className="card p-3">{primaryPayment?.account_name}</div>
                <div className="card d-flex flex-row align-items-center gap-3 p-3">
                  {primaryPayment?.icon && (<i className={parse_payment(primaryPayment)?.icon}></i>)}
                  {parse_payment(primaryPayment)?.value} Ending in{" "} Ending in{' '}
                  {primaryPayment?.cardNumber?.replaceAll('x', '')}
                </div>
                {primaryPayment?.expireDates && (<div className="card p-3">{primaryPayment?.expireDates}</div>)}
              </div>        

              {primaryPayment.card !== 'primary' && (
                <div className='mt-5'>
                 <button 
                 onClick={() => setItemPMethod({modal: 'activePMethod', item: primaryPayment})}
                 className='btn btn-primary'>Make this primary</button>
                </div>
              )}
            </div>
          )}

          {(selectedFunding === 'bankAccount' && paymentType === 'bankAccount') && (
            <div className='col-md-6'>
              <h4>Bank Account Information:</h4>
              <div className='mb-3'>
                <label className='form-label'>Name on Account</label>
                <input
                  type='text'
                  name='nameOnCard'
                  className='form-control form-control-solid form-control-lg'
                  placeholder='Name on Account'
                  value={bankAccountInfo.nameOnCard}
                  onChange={(e) =>
                    handleInputChange(e, setBankAccountInfo)
                  }
                />
              </div>
              <div className='mb-3'>
                <label className='form-label'>Routing Number</label>
                <input
                  type='text'
                  name='routingNumber'
                  className='form-control form-control-solid form-control-lg'
                  placeholder='Routing Number'
                  value={bankAccountInfo.routingNumber}
                  onChange={(e) =>
                    handleInputChange(e, setBankAccountInfo)
                  }
                />
              </div>
              <div className='mb-3'>
                <label className='form-label'>Account Number</label>
                <input
                  type='text'
                  name='accountNumber'
                  className='form-control form-control-solid form-control-lg'
                  placeholder='Account Number'
                  value={bankAccountInfo.accountNumber}
                  onChange={(e) =>
                    handleInputChange(e, setBankAccountInfo)
                  }
                />
              </div>
              <div className='mb-3'>
                <label className='form-label'>Account Type</label>
                <select
                  name='bankType'
                  className='form-control form-control-solid form-control-lg'
                  value={bankAccountInfo.bankType}
                  onChange={(e) => handleInputChange(e, setBankAccountInfo)}
                >
                  <option value='checking'>Checking</option>
                  <option value='savings'>Savings</option>
                </select>
              </div>
            </div>
          )}

          {((selectedFunding === 'creditCard' && paymentType === 'creditCard') || (selectedFunding === 'debitCard' && paymentType === 'debitCard')) && (
            <div style={{ flex: 1 }}>
              <h4>Card Information</h4>
                <div className="row mb-3">
                <div className="col-md-6">
                <label className="form-label">Name on Card</label>
                <input
                  type="text"
                  name="nameOnCard"
                  className="form-control form-control-solid form-control-lg"
                  placeholder="Name on Card"
                  value={creditCardInfo.nameOnCard}
                  onChange={(e) => handleInputChange(e, setCreditCardInfo)}
                />
                </div>

              <div className='d-flex flex-column mb-7 fv-row col-md-6'>
                <label className='required fs-6 fw-bold form-label mb-2'>Card Number</label>
                <div className='position-relative'>
                  <input
                    type='text'
                    className='form-control form-control-solid form-control-lg'
                    placeholder='Enter card number'
                    name='cardNumber'
                    value={creditCardInfo.cardNumber}
                    onChange={(e) => handleInputChange(e, setCreditCardInfo)}
                  />

                  <div className='position-absolute translate-middle-y top-50 end-0 me-5'>
                    <img
                      src={toAbsoluteUrl('/media/svg/card-logos/visa.svg')}
                      alt=''
                      className='h-25px'
                    />
                    <img
                      src={toAbsoluteUrl('/media/svg/card-logos/mastercard.svg')}
                      alt=''
                      className='h-25px'
                    />
                    <img
                      src={toAbsoluteUrl('/media/svg/card-logos/american-express.svg')}
                      alt=''
                      className='h-25px'
                    />
                  </div>
                </div>
              </div>
              </div>
              <div className="row mb-3">
                <div className="col-md-4">
                  <label className="form-label">Expiry Month</label>
                  <select
                    name="cardExpiryMonth"
                    className="form-control form-control-solid form-control-lg"
                    placeholder="MM"
                    value={creditCardInfo.cardExpiryMonth}
                    onChange={(e) => handleInputChange(e, setCreditCardInfo)}
                  >
                    <option value='' disabled>Select Month</option>
                    <option value='1'>1</option>
                    <option value='2'>2</option>
                    <option value='3'>3</option>
                    <option value='4'>4</option>
                    <option value='5'>5</option>
                    <option value='6'>6</option>
                    <option value='7'>7</option>
                    <option value='8'>8</option>
                    <option value='9'>9</option>
                    <option value='10'>10</option>
                    <option value='11'>11</option>
                    <option value='12'>12</option>
                  </select>
                </div>
                <div className="col-md-4">
                  <label className="form-label">Expiry Year</label>
                  <select
                    name="cardExpiryYear"
                    className="form-control form-control-solid form-control-lg"
                    placeholder="YYYY"
                    value={creditCardInfo.cardExpiryYear}
                    onChange={(e) => handleInputChange(e, setCreditCardInfo)}
                  >
                    <option value='' disabled>Select expiry year</option>
                    <option value='2021'>2021</option>
                    <option value='2022'>2022</option>
                    <option value='2023'>2023</option>
                    <option value='2024'>2024</option>
                    <option value='2025'>2025</option>
                    <option value='2026'>2026</option>
                    <option value='2027'>2027</option>
                    <option value='2028'>2028</option>
                    <option value='2029'>2029</option>
                    <option value='2030'>2030</option>
                    <option value='2031'>2031</option>
                  </select>
                </div>
                <div className="col-md-4">
                  <label className="form-label">CVV</label>
                  <input
                    type="text"
                    name="cardCvv"
                    className="form-control form-control-solid form-control-lg"
                    placeholder="CVV"
                    value={creditCardInfo.cardCvv}
                    onChange={(e) => handleInputChange(e, setCreditCardInfo)}
                  />
                </div>
              </div>

              <div className="mb-3">
                <label className="form-label">Billing Address</label>
                <input
                  type="text"
                  name="billingAddress"
                  className="form-control form-control-solid form-control-lg"
                  placeholder="Billing Address"
                  value={creditCardInfo.billingAddress}
                  onChange={(e) => handleInputChange(e, setCreditCardInfo)}
                />
              </div>

              <div className="row mb-3">
                <div className="col-md-6">
                    <label className="form-label">Country</label>
                    <select
                      name="billingCountry"
                      className="form-select form-select-lg form-select-solid"
                      onChange={(e) => handleInputChange(e, setCreditCardInfo)}
                    >
                      <option value="">Select Country</option>
                      {COUNTRIES_ISO_3166_ALPHA_3.map((c, idx) => (
                        <option key={c.alpha_3 + '_' + idx} value={c.alpha_3}>
                          {c.name}
                        </option>
                      ))}
                    </select>
                  </div>
                <div className="col-md-6">
                  <label className="form-label">State/Province</label>
                  <select
                    name="billingStateProvince"
                    className="form-select form-select-lg form-select-solid"
                    onChange={(e) => handleInputChange(e, setCreditCardInfo)}
                    disabled={!creditCardInfo.billingCountry}
                  >
                    <option value="">Select State/Province</option>
                    {COUNTRIES_ISO_3166_ALPHA_3.find((c) => c.alpha_3 === creditCardInfo.billingCountry)?.divisions?.map((d) => (
                      <option key={d.code} value={d.code}>
                        {d.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-md-6">
                  <label className="form-label">Postal Code</label>
                  <input
                    type="text"
                    name="billingPostalCode"
                    className="form-control form-control-solid form-control-lg"
                    placeholder="Postal Code"
                    value={creditCardInfo.billingPostalCode}
                    onChange={(e) => handleInputChange(e, setCreditCardInfo)}
                  />
                </div>
                <div className="col-md-6">
                  <label className="form-label">City</label>
                  <input
                    type="text"
                    name="billingCity"
                    className="form-control form-control-solid form-control-lg"
                    placeholder="City"
                    value={creditCardInfo.billingCity}
                    onChange={(e) => handleInputChange(e, setCreditCardInfo)}
                  />
                </div>
              </div>
            </div>
          )}
        </div>

        <button disabled={primaryPayment?.card === 'primary' || !!primaryPayment} className='btn btn-primary' type='submit'>
          Submit
        </button> 
      </form>
        
      {itemPMethod?.modal === 'activePMethod' && (
        <PaymentMethodModal
          header='Active Payment Method'
          body='Make this card the primary credit card?'
          setItem={setItemPMethod}
          pMethodLoading={isActivatePMethodLoading}
          onPMethod={activatePaymentMethod}
        />
      )}
      {itemPMethod?.modal === 'deletePMethod' && (
        <PaymentMethodModal
          header='Delete Payment Method'
          body={`Are you sure to delete this credit card ending with ${itemPMethod.item.cardNumber?.replaceAll(
            'x',
            ''
          )}?`}
          setItem={setItemPMethod}
          pMethodLoading={isDeletePMethodLoading}
          onPMethod={deletePaymentMethod}
        />
      )}
    </div>
  )
}

export default PaymentMethodField
