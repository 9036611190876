import React, { useState } from 'react';
import { useAuth } from '../modules/auth';
import * as Yup from 'yup';
import { FaAddressCard } from 'react-icons/fa';
import { ErrorMessage, Field, Form, Formik, FormikHelpers } from 'formik';
import { Alert, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { axiosPrivate } from '../../axiosInstance';
import { IoIosInformationCircleOutline } from 'react-icons/io';
import './AddProgramPage.scss';
import {COUNTRIES_ISO_3166_ALPHA_3} from '../../utils'
import { GRADE_ENTERING, T_SHIRT_SIZE } from '../modules/application/core/_models';

interface FormValues {
  nationality: string;
  first_name: string;
  last_name: string;
  email: string;
  birth_date: string;
  mobile: string;
  gender: string;
  relationship: string;
  other_gender?: string;
  other_relationship?: string;
  sub_type?: string;
  country: string | null;
  nickname: string | null;
  sex_assigned_at_birth: string | null;
  city: string | null;
  state_province: string | null;
  postal_code: string | null;
  address: string | null;
  pronouns: string | null;
  t_shirt_size: string | null;
  grade_school: string | null;
  school_attending: string | null;
  grade_point_average: string | null;
}

const ContactDetailSchema = (sub_type: string) => {
  return sub_type === 'guardian'
    ? Yup.object().shape({
        sub_type: Yup.string().nullable().required('Member type is required'),
        relationship: Yup.string().nullable().required('Relationship is required'),
        first_name: Yup.string().nullable().required('First Name is required'),
        last_name: Yup.string().nullable().required('Last Name is required'),
        email: Yup.string().nullable().email('Invalid email format').required('Email is required'),
        mobile: Yup.string().nullable().required('Mobile is required'),
        birth_date: Yup.date()
          .nullable()
          .required('Birthdate is required')
          .max(new Date(), 'Birthdate cannot be in the future'),
        gender: Yup.string().nullable().required('Gender is required'),
        country: Yup.string().nullable().required('Country is required'),
        city: Yup.string().nullable().required('City is required'),
        state_province: Yup.string().nullable().required('State or province is required'),
        postal_code: Yup.string().nullable().required('Postal code is required'),
        address: Yup.string().nullable().required('Address is required'),
        nationality: Yup.string().nullable().required('Nationality is required'),
      })
      : Yup.object().shape({
        // No need to require these fields for non-guardian sub_types
        first_name: Yup.string().nullable().required('First name is required'),
        last_name: Yup.string().nullable().required('Last name is required'),
        email: Yup.string().nullable().required('Email is required'),
        mobile: Yup.string().nullable().required('Mobile is required'),
        birth_date: Yup.date().nullable().required().max(new Date(), 'Birthdate cannot be in the future'),
        gender: Yup.string().nullable().required('Gender is required'),
        country: Yup.string().nullable().required('Country is required'),
        city: Yup.string().nullable().required('City is required'),
        state_province: Yup.string().nullable().required('State or province is required'),
        postal_code: Yup.string().nullable().required('Postal Code is required'),
        address: Yup.string().nullable().required('Address is required'),
        nationality: Yup.string().nullable().required('Nationality is required'),
        // Fields not required for other sub_types
        pronouns: Yup.string().nullable().required('Pronouns is required'),
        t_shirt_size: Yup.string().nullable().required('Tshirt Size is required'),
        grade_school: Yup.string().nullable().required('Current grade is required'),
        school_attending: Yup.string().nullable().required('School attending is required'),
        grade_point_average: Yup.string().nullable().required('Grade point average is required'),
      });
};
const AddProfilePage: React.FC = () => {
  const [message, setMessage] = useState<string>('');
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const { state }: any = useLocation()

  const subType = state?.sub_type === "Child/Traveler" ? "child" : state?.sub_type === "Parent/Guardian" ? "guardian" : "";

  const initialValues: FormValues = {
    first_name: '',
    last_name: '',
    email: '',
    birth_date: '',
    mobile: '',
    gender: '',
    relationship: '',
    sub_type: subType || "",
    nationality: '',
    country: '',
    nickname: '',
    sex_assigned_at_birth: '',
    city: '',
    state_province: '',
    postal_code: '',
    address: '',
    pronouns: '',
    t_shirt_size: '',
    grade_school: '',
    school_attending: '',
    grade_point_average: ''
  };

  const onSubmit = async (values: FormValues, actions: FormikHelpers<FormValues>) => {
    const submitValues = {
      ...values,
      nickname: values.nickname || null,
      sex_assigned_at_birth: values.sex_assigned_at_birth || null,
      pronouns: values.pronouns || null,
      t_shirt_size: values.t_shirt_size || null,
      school_attending: values.school_attending || null,
      grade_point_average: values.grade_point_average || null,
      grade_school: values.grade_school || null,
      gender: values.gender === 'others' ? values.other_gender : values.gender,
      relationship: values.relationship === 'others' ? values.other_relationship : values.relationship,
      nationality: values.nationality,
    };

    console.log(values.nationality)

    delete submitValues.other_gender;
    delete submitValues.other_relationship;

    try {
      await axiosPrivate.post('/add-contact', submitValues);
      actions.resetForm();
      setMessage('Verification Link has been sent to the provided email');
    } catch (error) {
      console.error('Error submitting form:', error);
    } finally {
      if (state?.page && state?.page === 'enrollment') {
        console.log(state?.page, 'state?.page')
        const selectedCampus = localStorage.getItem('selectedCampus');
        navigate(-1); 
      } else {
        navigate(`/my-contacts/${currentUser?.currentClient}`)
      }
      
      setTimeout(() => {
        setMessage('')
      }, 3000)
    }
  };

  const handleDiscard = () => {
    navigate(-1);
  };

  const renderTooltip = (text: string) => (
    <Tooltip id="tooltip">{text}</Tooltip>
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={ContactDetailSchema(subType)}
      onSubmit={onSubmit}
    >
      {({ values, isSubmitting, isValid, setFieldValue }) => {
        const handleContactTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
          const { value } = e.target;
          setFieldValue('sub_type', value);
        };

        const handleGenderChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
          const { value } = e.target;
          setFieldValue('gender', value);
          if (value !== 'others') {
            setFieldValue('other_gender', '');
          }
        };

        const handleRelationshipChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
          const { value } = e.target;
          setFieldValue('relationship', value);
          if (value !== 'others') {
            setFieldValue('other_relationship', '');
          }
        };

        return (
          <div className="card card-body">
            <div className="d-flex align-items-center justify-content-center gap-3 pb-5">
              <div style={{ background: '#f7942a', flex: 1, borderRadius: '5px' }} className='d-flex justify-content-center align-items-center w-100 mx-5 text-center py-2 text-light'>
                <h1 className='fw-bolder fs-2x text-light text-center'>New Contact Details</h1>
              </div>
            </div>
            <Form>
              <div className="f-row row mb-3">
                <div className="col-lg-6">
                  <div className="mb-3">
                    <label className="form-label fw-bolder text-dark fs-6 required" htmlFor="sub_type">
                      Member type
                      <OverlayTrigger
                        placement="right"
                        overlay={renderTooltip("Select the type of contact you are adding.")}
                      >
                        <span className="tooltip-icon ms-2">
                          <IoIosInformationCircleOutline size={15} />
                        </span>
                      </OverlayTrigger>
                    </label>
                    <Field as="select" name="sub_type" className="form-select form-select-lg form-select-solid" onChange={handleContactTypeChange}>
                      <option value="">Select a Contact type</option>
                      <option value="guardian">Parent/Guardian</option>
                      <option value="child">Student/Traveler</option>
                    </Field>
                    <ErrorMessage name="sub_type" component="div" className="text-danger" />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="mb-3">
                    <label className="form-label fw-bolder text-dark fs-6 required" htmlFor="relationship">
                      Relationship to Account Holder
                      <OverlayTrigger
                        placement="right"
                        overlay={renderTooltip("Specify the relationship of this contact to the account holder.")}
                      >
                        <span className="tooltip-icon ms-2">
                          <IoIosInformationCircleOutline size={15} />
                        </span>
                      </OverlayTrigger>
                    </label>
                    <Field as="select" name="relationship" className="form-select form-select-lg form-select-solid" onChange={handleRelationshipChange}>
                      <option value="">Select a relationship</option>
                      <option value="child">Child</option>
                      <option value="spouse">Spouse</option>
                      <option value="sibling">Sibling</option>
                      <option value="agent">Agent</option>
                      <option value="coach">Coach</option>
                      <option value="others">Others, please specify</option>
                    </Field>
                    {values.relationship === 'others' && (
                      <Field type="text" name="other_relationship" className="form-control form-control-lg mt-2" placeholder="Specify Relationship" />
                    )}
                    <ErrorMessage name="relationship" component="div" className="text-danger" />
                  </div>
                </div>
              </div>
              
              {/* First Name and Last Name */}
              <div className="f-row row mb-3">
                <div className="col-lg-6">
                  <div className="mb-3">
                    <label className="form-label fw-bolder text-dark fs-6 required" htmlFor="first_name">First Name</label>
                    <Field type="text" name="first_name" className="form-control form-control-lg" />
                    <ErrorMessage name="first_name" component="div" className="text-danger" />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="mb-3">
                    <label className="form-label fw-bolder text-dark fs-6 required" htmlFor="last_name">Last Name</label>
                    <Field type="text" name="last_name" className="form-control form-control-lg" />
                    <ErrorMessage name="last_name" component="div" className="text-danger" />
                  </div>
                </div>
              </div>

              {/* Email and Mobile number */}
              <div className="f-row row mb-3">
                <div className="col-lg-6">
                  <div className="mb-3">
                    <label className="form-label fw-bolder text-dark fs-6 required" htmlFor="email">Email</label>
                    <span className="text-muted small fst-italic d-block">Email must be different than account holder email</span>
                    <Field type="email" name="email" className="form-control form-control-lg" />
                    <ErrorMessage name="email" component="div" className="text-danger" />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="mb-3">
                    <label className="form-label fw-bolder text-dark fs-6 required" htmlFor="mobile">Phone Number</label>
                    <Field type="text" name="mobile" className="form-control form-control-lg" />
                    <ErrorMessage name="mobile" component="div" className="text-danger" />
                  </div>
                </div>
              </div>

              {/* Gender and Date of birth */}
              <div className="f-row row mb-3">
              <div className="col-lg-6">
                  <div className="mb-3">
                    <label className="form-label fw-bolder text-dark fs-6 required" htmlFor="birth_date">Date of Birth</label>
                    <Field
                      type="date"
                      name="birth_date"
                      className="form-control form-control-lg"
                    />
                    <ErrorMessage name="birth_date" component="div" className="text-danger" />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="mb-3">
                    <label className="form-label fw-bolder text-dark fs-6 required" htmlFor="gender">Gender</label>
                    <Field as="select" name="gender" className="form-select form-select-lg form-select-solid" onChange={handleGenderChange}>
                      <option value="">Select a gender</option>
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                      <option value="non_binary">Non-binary</option>
                      <option value="preferred_not_to_say">Preferred not to say</option>
                      <option value="others">Other, please specify</option>
                    </Field>
                    {values.gender === 'others' && (
                      <Field type="text" name="other_gender" className="form-control form-control-lg mt-2" placeholder="Specify Gender" />
                    )}
                    <ErrorMessage name="gender" component="div" className="text-danger" />
                  </div>
                </div>
              </div>
              
              {/* Nickname and Sex Assigned at Birth */}
              {values.sub_type === 'child' && (
                <>
                  <div className="f-row row mb-3">
                    <div className="col-lg-6">
                      <div className="mb-3">
                        <label className="form-label fw-bolder text-dark fs-6" htmlFor="nickname">Nickname</label>
                        <Field type="text" name="nickname" className="form-control form-control-lg" />
                        <ErrorMessage name="nickname" component="div" className="text-danger" />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="mb-3">
                        <label className="form-label fw-bolder text-dark fs-6 required" htmlFor="sex_assigned_at_birth">Sex Assigned at Birth</label>
                        <Field as="select" name="sex_assigned_at_birth" className="form-select form-select-lg form-select-solid" required>
                        <option value="">Select sex assigned at birth </option>
                        <option value='Male'>Male</option>
                        <option value='Female'>Female</option>
                        </Field>
                        <ErrorMessage name="sex_assigned_at_birth" component="div" className="text-danger" />
                      </div>
                    </div>
                  </div>
                </>
              )}

              {/* Country and City */}
              <div className="f-row row mb-3">
                <div className="col-lg-6">
                  <div className="mb-3">
                    <label className="form-label fw-bolder text-dark fs-6 required" htmlFor="country">
                      Country
                      <OverlayTrigger
                        placement="right"
                        overlay={renderTooltip('Which passport will you be traveling with?')}
                      >
                        <span className="tooltip-icon ms-2">
                          <IoIosInformationCircleOutline size={15} />
                        </span>
                      </OverlayTrigger>
                    </label>
                    <Field as="select" name="country" className="form-select form-select-lg form-select-solid">
                      <option value="">Select Country</option>
                      {COUNTRIES_ISO_3166_ALPHA_3.map((c, idx) => {
                          return (
                            <option value={c.alpha_3} key={c.alpha_3+'_'+idx}>
                              {c.name}
                            </option>
                          )
                        })}
                    </Field>
                    <ErrorMessage name="country" component="div" className="text-danger" />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="mb-3">
                    <label className="form-label fw-bolder text-dark fs-6 required" htmlFor="city">City</label>
                    <Field type="text" name="city" className="form-control form-control-lg" />
                    <ErrorMessage name="city" component="div" className="text-danger" />
                  </div>
                </div>
              </div>

              {/* State and Zip code Field*/}
              <div className="f-row row mb-3">
                <div className="col-lg-6">
                  <div className="mb-3">
                    <label className="form-label fw-bolder text-dark fs-6 required" htmlFor="state_province">
                      State/Province
                      <OverlayTrigger
                        placement="right"
                        overlay={renderTooltip('Which passport will you be traveling with?')}
                      >
                        <span className="tooltip-icon ms-2">
                          <IoIosInformationCircleOutline size={15} />
                        </span>
                      </OverlayTrigger>
                    </label>
                    <Field
                      as="select"
                      name="state_province"
                      className="form-select form-select-lg form-select-solid"
                    >
                      <option value="">Select State/Province</option>
                      {COUNTRIES_ISO_3166_ALPHA_3.map((c, idx) => {
                        if (c.alpha_3 == values.country) {
                          let provinces = c.divisions?.map((d, id) => {
                            return (
                              <option value={d.code} key={d.code+'_'+id}>
                                {d.name}
                              </option>
                            )
                          })
                          return provinces
                        }
                      })}
                    </Field>
                    <ErrorMessage name="state_province" component="div" className="text-danger" />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="mb-3">
                    <label className="form-label fw-bolder text-dark fs-6 required" htmlFor="postal_code">Zip Code</label>
                    <Field type="text" name="postal_code" className="form-control form-control-lg" />
                    <ErrorMessage name="postal_code" component="div" className="text-danger" />
                  </div>
                </div>
              </div>

              {/* Nationality and Address Field */}
              <div className="f-row row mb-3">
                <div className="col-lg-6">
                    <div className="mb-3">
                      <label className="form-label fw-bolder text-dark fs-6 required" htmlFor="address">Address</label>
                      <Field type="text" name="address" className="form-control form-control-lg" />
                      <ErrorMessage name="address" component="div" className="text-danger" />
                    </div>
                  </div>
                <div className="col-lg-6">
                  <div className="mb-3">
                    <label className="form-label fw-bolder text-dark fs-6 required" htmlFor="nationality">
                      In which country do you hold your primary citizenship?
                      <OverlayTrigger
                        placement="right"
                        overlay={renderTooltip('Which passport will you be traveling with?')}
                      >
                        <span className="tooltip-icon ms-2">
                          <IoIosInformationCircleOutline size={15} />
                        </span>
                      </OverlayTrigger>
                    </label>
                    <Field as="select" name="nationality" className="form-select form-select-lg form-select-solid">
                      <option value="">Select your nationality</option>
                      {COUNTRIES_ISO_3166_ALPHA_3.map((country, index) => (
                        <option key={index} value={country.name}>{country.name}</option>
                      ))}
                    </Field>
                    <ErrorMessage name="nationality" component="div" className="text-danger" />
                  </div>
                </div>
              </div>

              {/* Pronouns and T-shirt field */}
              {values.sub_type === 'child' && (
                <>
                  <div className="f-row row mb-3">
                    <div className="col-lg-6">
                      <div className="mb-3">
                      <label className="form-label fw-bolder text-dark fs-6 required" htmlFor="pronouns">Pronouns</label>
                      <Field as="select" name="pronouns" className="form-select form-select-lg form-select-solid" required>
                        <option value="">Select Pronouns</option> 
                        <option value="she, her, hers">she, her, hers</option>
                        <option value="he, him, his">he, him, his</option>
                        <option value="they, them, theirs">they, them, theirs</option>
                        <option value="I do not use he, she, or they, and ask that I'm addressed only by name">
                          I do not use he, she, or they, and ask that I'm addressed only by name
                        </option>
                      </Field>
                        <ErrorMessage name="pronouns" component="div" className="text-danger" />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="mb-3">
                        <label className="form-label fw-bolder text-dark fs-6 required" htmlFor="t_shirt_size">T-Shirt Size</label>
                        <Field as="select" name="t_shirt_size" className="form-select form-select-lg form-select-solid" required>
                          <option value="">Select Size</option>
                          {T_SHIRT_SIZE.map((size, idx) => (
                            <option value={size.value} key={idx}>
                              {size.adult}
                            </option>
                          ))}
                        </Field>
                        <ErrorMessage name="t_shirt_size" component="div" className="text-danger" />
                      </div>
                    </div>
                  </div>
                </>
              )}

              {/* Current Grade and School Attending next fall field */}
              {values.sub_type === 'child' && (
                <>
                  <div className="f-row row mb-3">
                    <div className="col-lg-6">
                      <div className="mb-3">
                      <label className="form-label fw-bolder text-dark fs-6 required" htmlFor="grade_school">Current grade</label>
                      <Field as="select" name="grade_school" className="form-select form-select-lg form-select-solid" required>
                        <option value="">Select Grade</option>
                        {GRADE_ENTERING.map((grade, idx) => (
                          <option value={grade.value} key={grade.value + '_' + idx}>
                            {grade.label}
                          </option>
                        ))}
                      </Field>
                        <ErrorMessage name="grade_school" component="div" className="text-danger" />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="mb-3">
                        <label className="form-label fw-bolder text-dark fs-6 required" htmlFor="school_attending">School Attending Next Fall</label>
                        <Field type="text" name="school_attending" className="form-control form-control-lg" required />
                        <ErrorMessage name="school_attending" component="div" className="text-danger" />
                      </div>
                    </div>
                  </div>
                </>
              )}

              {/* Grade point average field*/}.
              {values.sub_type === 'child' && (
                <>
                  <div className="f-row row mb-3">
                    <div className="col-lg-6">
                      <div className="mb-3">
                        <label className="form-label fw-bolder text-dark fs-6 required" htmlFor="grade_point_average">Grade Point Average</label>
                        <Field type="text" name="grade_point_average" className="form-control form-control-lg" required/>
                        <ErrorMessage name="grade_point_average" component="div" className="text-danger" />
                      </div>
                    </div>
                  </div>
                </>
              )}


              {/* Form Actions */}
              <div className="d-flex justify-content-start gap-3">
                <Button variant="danger" type="button" onClick={handleDiscard}>Discard</Button>
                <Button variant="primary" type="submit" disabled={isSubmitting || !isValid}>Submit</Button>
              </div>
              {message && <Alert variant="success" className="mt-3">{message}</Alert>}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
};

export default AddProfilePage;
