import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Button, Container, Row, Col, Form as BootstrapForm, Alert, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { axiosPrivate } from '../../axiosInstance';
import { COUNTRIES_ISO_3166_ALPHA_3 } from '../../utils';
import { IoIosInformationCircleOutline } from 'react-icons/io';
import { ScrollTopComponent } from '../../_metronic/assets/ts/components';
import { getAuthPromise, PREV_AUTH_LOCAL_STORAGE_KEY } from '../modules/auth/core/AuthHelpers';
import './UpdateContactMember.scss'
import ScrollArrow from './ScrollDownArrow';
import { GRADE_ENTERING, T_SHIRT_SIZE } from '../modules/application/core/_models';

interface User {
  address: string | null;
  birth_date: string;  // ISO 8601 date string
  city: string | null;
  email: string;
  country: string | null;
  created_at: string;  // ISO 8601 date string
  first_name: string;
  gender: string;
  grade_school: string | null;
  grade_point_average: string | null;
  id: number;
  last_name: string;
  nickname: string | null;
  phone: string;
  postal_code: string | null;
  profile_url: string | null;
  pronouns: string | null;
  relationship: string;
  school_attending: string | null;
  school_of_attending: string | null;
  sex_assigned_at_birth: string | null;
  state_province: string | null;
  t_shirt_size: string | null;
  updated_at: string;  // ISO 8601 date string
  uuid: string;
  sub_type: string;
  other_relationship?: string | null
  nationality: string | null;
}

const UpdateContactMember = () => {

  const [contact, setContact] = useState<User | null>(null);
  const [message, setMessage] = useState({ success: '', error: '' });
  const [file, setFile] = useState<File | null>(null);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const navigate = useNavigate();
  const [hasAdmin, setHasAdmin] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState('general')
  const { id } = useParams<{ id: string }>();
  const currentRoute = window.location.pathname.split('/')[2] || ''
  const [currentStep, setCurrentStep] = useState<number>(() => {
    const savedStep = localStorage.getItem('currentStep');
    return savedStep ? JSON.parse(savedStep) : 0;
  });


  const UserSchema = contact?.sub_type === 'child' ? Yup.object().shape({
    first_name: Yup.string().nullable().required('First name is required'),
    last_name: Yup.string().nullable().required('Last name is required'),
    phone: Yup.string().nullable().required('Phone number is required'),
    address: Yup.string().nullable().required('Address is required'),
    birth_date: Yup.date()
        .nullable()
        .required('Birthdate is required')
        .max(new Date(), 'Birthdate cannot be in the future'),
    city: Yup.string().nullable().required('City is required'),
    country: Yup.string().nullable().required('Country is required'),
    gender: Yup.string().nullable().required('Gender is required'),
    grade_school: Yup.string().nullable().required('School grade is required'),
    grade_point_average: Yup.string().nullable().required('Grade point average is required'),
    nickname: Yup.string().nullable(),
    postal_code: Yup.string().nullable().required('Postal code is required'),
    pronouns: Yup.string().nullable().required('Pronouns are required'),
    relationship: Yup.string().nullable().required('Relationship status is required'),
    school_attending: Yup.string().nullable().required('School attending is required'),
    sex_assigned_at_birth: Yup.string().nullable().required('Sex assigned at birth is required'),
    state_province: Yup.string().nullable().required('State or province is required'),
    t_shirt_size: Yup.string().nullable().required('T-shirt size is required'),
    nationality: Yup.string().nullable().required('Nationality is required'),
  }) : Yup.object().shape({
    first_name: Yup.string().nullable().required('First name is required'),
    last_name: Yup.string().nullable().required('Last name is required'),
    phone: Yup.string().nullable().required('Phone number is required'),
    address: Yup.string().nullable().required('Address is required'),
    birth_date: Yup.date()
        .nullable()
        .required('Birthdate is required')
        .max(new Date(), 'Birthdate cannot be in the future'),
    city: Yup.string().nullable().required('City is required'),
    country: Yup.string().nullable().required('Country is required'),
    gender: Yup.string().nullable().required('Gender is required'),
    postal_code: Yup.string().nullable().required('Postal code is required'),
    relationship: Yup.string().nullable().required('Relationship status is required'),
    state_province: Yup.string().nullable().required('State or province is required'),
    nationality: Yup.string().nullable().required('Nationality is required'),
  });

  const subTypeLabels = [
    { type: "child", label: "Student" },
    { type: "guardian", label: "Guardian" },
  ];

  const fetchContactMemberProfile = async () => {
    try {
      const { data } = await axiosPrivate.get(`/contact/user/${id}`);
      setContact(data?.data);
    } catch (error) {
      console.error('Error fetching contact member profile:', error);
    }
  };

  useEffect(() => {
    fetchContactMemberProfile();
  }, [id]);

  useEffect(() => {
    const getCurrentAuth = async () => {
      const PrevAuth = await getAuthPromise(PREV_AUTH_LOCAL_STORAGE_KEY)
      if (PrevAuth) {
        setHasAdmin(true)
      } else {
        setHasAdmin(false)
      }
    }
    getCurrentAuth()
  }, []);

  const handleSubmit = async (values: User) => {

    const submitValues = {
      ...values,
      relationship: values.relationship === 'others' ? values.other_relationship : values.relationship,
    };

    delete submitValues.other_relationship;

    try {
      const res = await axiosPrivate.post(`/contact/${id}/update`, submitValues);
      if (res.status === 200) {
        setMessage({ success: res.data.message || 'Updated successfully!', error: '' });
        navigate(`/dashboard/${currentRoute}`);
      }
    } catch (error: any) {
      setMessage({ success: '', error: error.response.data.message || 'There is something wrong with the server' });
    } finally {
      ScrollTopComponent.goTop()  
      setTimeout(() => {
        setMessage({ error: '', success: '' });
      }, 5000);
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setFile(event.target.files[0]);
    }
  };

  const handleFileUpload = async () => {
    if (!file) return;
    const formData = new FormData();
    formData.append('file', file);
    setSubmitting(true);
    try {
      const res = await axiosPrivate.post(`/contact/${id}/upload-profile`, formData);
      if (res.status === 200) {
        setMessage({ success: 'Photo uploaded successfully!', error: '' });
        fetchContactMemberProfile();
      }
    } catch (error: any) {
      setMessage({ success: '', error: error.response.data.message || 'There is something wrong with the server' });
    } finally {
      setSubmitting(false); 
      setTimeout(() => {
        setMessage({ error: '', success: '' });
      }, 5000);
    }
  };

  const renderTooltip = (text: string) => (
    <Tooltip id="tooltip">{text}</Tooltip>
  );

  const handleToggle = (tab: string) => {
    setActiveTab(tab)
  }

  return (
    <Container>
      {contact ? (
        <>
        <div className='d-flex justify-content-between align-items-center w-100 mx-auto'>
          <div style={{ width: 150 }} className='d-flex align-items-center'>
            {currentStep === 0 && (
              <Button className='w-100' style={{ fontSize: 20 }} variant="secondary" type="button" onClick={() => navigate(-1)}>
                Previous
              </Button>
            )}
          </div>
          <div style={{ background: '#f7942a', flex: 1, borderRadius: '5px' }} className='d-flex justify-content-center align-items-center w-100 mx-5 text-center py-2 text-light'>
            <h1 className='fw-bolder fs-2x text-light text-center '>Member: {`${contact?.first_name} ${contact?.last_name}`}</h1>
          </div>
        </div>
        {/* <div className='btn-school d-flex'>
        {contact?.sub_type === 'child' && (
          <>
            <div
              className={`px-5 py-2 ${activeTab === 'general' ? 'btn-active' : 'btn-default'}`}
              onClick={() => handleToggle('general')}
            >
              General Profile
            </div>
            <div
              className={`px-5 py-2 ${activeTab === 'medical' ? 'btn-active' : 'btn-default'}`}
              onClick={() => handleToggle('medical')}
            >
              Medical Section
            </div>
          </>
        )}
      </div> */}
      {activeTab === 'general' && (
        <>
          {message ?  
          <div className='mt-2'>
            {message.success && <Alert variant='success'>{message.success}</Alert>}
            {message.error && <Alert variant='danger'>{message.error}</Alert>}
          </div>
          : ''} 
          {contact?.profile_url && (
            <img 
              style={{
                width: '200px',
                height: '200px',
                objectFit: 'cover',
                display: 'block',
                border: '3px #f7942a solid'
              }} 
              className='rounded-circle mb-2 mx-auto'
              src={`${contact?.profile_url}`} 
              alt='Profile' 
            />
          )}
          <div className="mb-4">
            <BootstrapForm.Label>Upload Profile Photo</BootstrapForm.Label>
            <BootstrapForm.Group controlId="formFile">
              <BootstrapForm.Control type="file" onChange={handleFileChange} />
            </BootstrapForm.Group>
            <Button className="mt-2" onClick={handleFileUpload} disabled={submitting}>
              {submitting ? 'Uploading...' : 'Upload'}
            </Button>
          </div>
          <Formik
          initialValues={contact}
          validationSchema={UserSchema}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({ values, isSubmitting, setFieldValue }) => {
            const handleRelationshipChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
              const { value } = e.target;
              setFieldValue('relationship', value);
              if (value !== 'others') {
                setFieldValue('other_relationship', '');
              }
            };
            const handleContactTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
              const { value } = e.target;
              setFieldValue('sub_type', value);
            };
            return (
            <Form>
              <Row>
              <Col md={6}>
                <BootstrapForm.Group controlId="t_shirt_size">
                  <BootstrapForm.Label className='required'>Member Type </BootstrapForm.Label>
                    {hasAdmin ? (
                      <Field 
                        as="select" 
                        name="sub_type" 
                        className="form-control form-control-lg" 
                        onChange={handleContactTypeChange}
                      >
                        <option value="" disabled>Select a Contact type</option>
                        <option value="guardian">Parent/Guardian</option>
                        <option value="child">Student/Traveler</option>
                      </Field>
                    ) : (
                      <div className="form-control form-control-lg">
                        {subTypeLabels.find((item) => item.type === values.sub_type)?.label ||
                        values.sub_type.charAt(0).toUpperCase() + values.sub_type.slice(1)}
                      </div>
                    )}
                    <ErrorMessage
                      name="sub_type"
                      component="div"
                      className="text-danger"
                  />
                </BootstrapForm.Group>
              </Col>
                <Col md={6}>
                  <BootstrapForm.Group controlId="relationship">
                    <BootstrapForm.Label className='required'>Relationship to Account Holder 
                    <OverlayTrigger
                      placement="right"
                      overlay={renderTooltip("Specify the relationship of this contact to the account holder.")}
                    >
                      <span className="tooltip-icon ms-2">
                        <IoIosInformationCircleOutline size={15} />
                      </span>
                    </OverlayTrigger>
                    </BootstrapForm.Label>
                    <Field as="select" name="relationship" className="form-select form-select-lg form-select-solid" onChange={handleRelationshipChange}>
                      <option value="">Select a relationship</option>
                      <option value="child">Child</option>
                      <option value="spouse">Spouse</option>
                      <option value="sibling">Sibling</option>
                      <option value="agent">Agent</option>
                      <option value="coach">Coach</option>
                      <option value="others">Others, please specify</option>
                    </Field>
                    {values.relationship === 'others' && (
                      <Field 
                      type="text" 
                      name="other_relationship" 
                      className="form-control form-control-lg mt-2" 
                      placeholder="Specify Relationship" 
                      />
                    )}
                    <ErrorMessage
                      name="relationship"
                      component="div"
                      className="text-danger"
                    />
                  </BootstrapForm.Group>
                </Col>
              </Row>

              <Row className='mt-2' >
                <Col md={6}>
                  <BootstrapForm.Group controlId="first_name">
                    <BootstrapForm.Label className='required'>First Name</BootstrapForm.Label>
                    <Field
                      name="first_name"
                      className="form-control form-control-lg"
                    />
                    <ErrorMessage
                      name="first_name"
                      component="div"
                      className="text-danger"
                    />
                  </BootstrapForm.Group>
                </Col>
                <Col md={6}>
                  <BootstrapForm.Group controlId="last_name">
                    <BootstrapForm.Label className='required'>Last Name</BootstrapForm.Label>
                    <Field
                      name="last_name"
                      className="form-control form-control-lg"
                    />
                    <ErrorMessage
                      name="last_name"
                      component="div"
                      className="text-danger"
                    />
                  </BootstrapForm.Group>
                </Col>
              </Row>

              <Row className='mt-2' >
                <Col md={6}>
                  <BootstrapForm.Group controlId="email">
                    <BootstrapForm.Label className='required'>Email</BootstrapForm.Label>
                    {hasAdmin ? (
                      <Field
                        name="email"
                        type="email"
                        className="form-control form-control-lg" 

                      />
                    ) : (
                      <div className="form-control form-control-lg">{values.email}</div>
                    )}
                    <ErrorMessage
                    name="email"
                    component="div"
                    className="text-danger"
                    />
                  </BootstrapForm.Group>
                </Col>
                <Col md={6}>
                  <BootstrapForm.Group controlId="phone">
                    <BootstrapForm.Label className='required'>Phone Number</BootstrapForm.Label>
                    <Field
                      name="phone"
                      className="form-control form-control-lg"
                    />
                    <ErrorMessage
                      name="phone"
                      component="div"
                      className="text-danger"
                    />
                  </BootstrapForm.Group>
                </Col>
              </Row>

              <Row className='mt-2'>
                <Col md={6}>
                  <BootstrapForm.Group controlId="birth_date">
                    <BootstrapForm.Label className='required'>Date of Birth</BootstrapForm.Label>
                    <Field
                      name="birth_date"
                      type="date"
                      className="form-control form-control-lg"
                    />
                    <ErrorMessage
                      name="birth_date"
                      component="div"
                      className="text-danger"
                    />
                  </BootstrapForm.Group>
                </Col>
                <Col md={6}>
                  <BootstrapForm.Group controlId="gender">
                    <BootstrapForm.Label className='required'>Gender</BootstrapForm.Label>
                      <Field
                        as="select"
                        name="gender"
                        type="gender"
                        className="form-select form-select-lg form-select-solid" 
                      >
                        <option value="">Select a gender</option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                        <option value="non_binary">Non-binary</option>
                        <option value="preferred_not_to_say">Preferred not to say</option>
                        <option value="others">Other, please specify</option>
                      </Field>
                    <ErrorMessage
                      name="gender"
                      component="div"
                      className="text-danger"
                    />
                  </BootstrapForm.Group>
                </Col>
              </Row>
                    
              <Row className='mt-2'>
                {contact?.sub_type === 'child' && (
                  <Col md={6}>
                    <BootstrapForm.Group controlId="nickname">
                      <BootstrapForm.Label>Nickname</BootstrapForm.Label>
                      <Field
                        name="nickname"
                        className="form-control form-control-lg"
                      />
                      <ErrorMessage
                        name="nickname"
                        component="div"
                        className="text-danger"
                      />
                    </BootstrapForm.Group>
                  </Col>
                )}
                {contact?.sub_type === 'child' && (
                  <Col md={6}>
                    <BootstrapForm.Group controlId="sex_assigned_at_birth">
                      <BootstrapForm.Label className='required'>Sex Assigned at Birth</BootstrapForm.Label>
                      <Field
                        as="select"
                        name="sex_assigned_at_birth"
                        className="form-select form-select-lg form-select-solid"
                      >
                        <option value="">Select sex assigned at birth </option>
                        <option value='Male'>Male</option>
                        <option value='Female'>Female</option>
                      </Field>
                      <ErrorMessage
                        name="sex_assigned_at_birth"
                        component="div"
                        className="text-danger"
                      />
                    </BootstrapForm.Group>
                  </Col>
                )}
              </Row>

              <Row className='mt-2'>
                <Col md={6}>
                  <BootstrapForm.Group controlId="country">
                    <BootstrapForm.Label className='required'>Country</BootstrapForm.Label>
                      <Field 
                        as='select'
                        name='country'
                        className='form-select form-select-lg form-select-solid'
 
                      >
                        <option>Select Country</option>
                        {COUNTRIES_ISO_3166_ALPHA_3.map((c, idx) => {
                          return (
                            <option value={c.alpha_3} key={c.alpha_3+'_'+idx}>
                              {c.name}
                            </option>
                          )
                        })}
                      </Field>
                    <ErrorMessage
                      name="country"
                      component="div"
                      className="text-danger"
                    />
                  </BootstrapForm.Group>
                </Col>
                <Col md={6}>
                  <BootstrapForm.Group controlId="city">
                    <BootstrapForm.Label className='required'>City</BootstrapForm.Label>
                    <Field
                      name="city"
                      className="form-control form-control-lg"
                    />
                    <ErrorMessage
                      name="city"
                      component="div"
                      className="text-danger"
                    />
                  </BootstrapForm.Group>
                </Col>
              </Row>

              <Row className='mt-2'>
                <Col md={6}>
                  <BootstrapForm.Group controlId="state_province">
                    <BootstrapForm.Label className='required'>State/Province </BootstrapForm.Label>
                    <Field
                      as='select'
                      name='state_province'
                      className='form-select form-select-lg form-select-solid'
                    >
                      <option>Select State/Province</option>
                      {COUNTRIES_ISO_3166_ALPHA_3.map((c, idx) => {
                        if (c.alpha_3 == values.country) {
                          let provinces = c.divisions?.map((d, id) => {
                            return (
                              <option value={d.code} key={d.code+'_'+id}>
                                {d.name}
                              </option>
                            )
                          })
                          return provinces
                        }
                      })}
                    </Field>
                    <ErrorMessage
                      name="state_province"
                      component="div"
                      className="text-danger"
                    />
                  </BootstrapForm.Group>
                </Col>
                <Col md={6}>
                  <BootstrapForm.Group controlId="postal_code">
                    <BootstrapForm.Label className='required'>Zip Code</BootstrapForm.Label>
                    <Field
                      name="postal_code"
                      className="form-control form-control-lg"
                    />
                    <ErrorMessage
                      name="postal_code"
                      component="div"
                      className="text-danger"
                    />
                  </BootstrapForm.Group>
                </Col>
              </Row>

              <Row className='mt-2'>
                <Col md={6}>
                  <BootstrapForm.Group controlId="address">
                    <BootstrapForm.Label className='required'>Address</BootstrapForm.Label>
                    <Field
                      name="address"
                      className="form-control form-control-lg"
                    />
                    <ErrorMessage
                      name="address"
                      component="div"
                      className="text-danger"
                    />
                  </BootstrapForm.Group>
                </Col>
                <Col md={6}>
                  <BootstrapForm.Group controlId="nationality">
                    <BootstrapForm.Label className='required'>Nationality</BootstrapForm.Label>
                    <Field
                      name="nationality"
                      as="select"
                      className="form-select form-select-lg form-select-solid"
                    >
                      <option value="">Select Nationality</option>
                      {COUNTRIES_ISO_3166_ALPHA_3.map((country) => (
                        <option key={country.alpha_3} value={country.name}>
                          {country.name}
                        </option>
                      ))}
                    </Field>
                    <ErrorMessage
                      name="nationality"
                      component="div"
                      className="text-danger"
                    />
                  </BootstrapForm.Group>
                </Col>
              </Row>

              <Row className='mt-2'>
                {contact?.sub_type === 'child' && (
                <Col md={6}>
                  <BootstrapForm.Group controlId="pronouns">
                    <BootstrapForm.Label className='required'>Pronouns</BootstrapForm.Label>
                    <Field
                      as="select"
                      name="pronouns"
                      className="form-select form-select-lg form-select-solid"
                    >
                      <option value="">Select Pronouns</option> 
                      <option value="she, her, hers">she, her, hers</option>
                      <option value="he, him, his">he, him, his</option>
                      <option value="they, them, theirs">they, them, theirs</option>
                      <option value="I do not use he, she, or they, and ask that I'm addressed only by name">
                        I do not use he, she, or they, and ask that I'm addressed only by name
                      </option>
                    </Field>
                    <ErrorMessage
                      name="pronouns"
                      component="div"
                      className="text-danger"
                    />
                  </BootstrapForm.Group>
                </Col>
                )}
                {contact?.sub_type === 'child' && (
                <Col md={6}>
                  <BootstrapForm.Group controlId="t_shirt_size">
                    <BootstrapForm.Label className='required'>T-Shirt Size</BootstrapForm.Label>
                    <Field
                      as="select"
                      name="t_shirt_size"
                      className="form-select form-select-lg form-select-solid"
                    >
                      <option value="">Select Size</option>
                      {T_SHIRT_SIZE.map((size, idx) => (
                        <option value={size.value} key={idx}>
                          {size.adult}
                        </option>
                      ))}
                    </Field>
                    <ErrorMessage
                      name="t_shirt_size"
                      component="div"
                      className="text-danger"
                    />
                  </BootstrapForm.Group>
                </Col>
                )}
              </Row>
              
              {contact?.sub_type === 'child' && (
              <Row className='mt-2'>
                <Col md={6}>
                  <BootstrapForm.Group controlId="grade_school">
                    <BootstrapForm.Label className='required'>Current grade</BootstrapForm.Label>
                    <Field
                      as="select"
                      name="grade_school"
                      className="form-select form-select-lg form-select-solid"
                      >
                      <option value="">Select Grade</option>
                      {GRADE_ENTERING.map((grade, idx) => (
                        <option value={grade.value} key={grade.value + '_' + idx}>
                          {grade.label}
                        </option>
                      ))}
                    </Field>
                    <ErrorMessage
                      name="grade_school"
                      component="div"
                      className="text-danger"
                      />
                  </BootstrapForm.Group>
                </Col>
                <Col md={6}>
                  <BootstrapForm.Group controlId="school_attending">
                    <BootstrapForm.Label className='required'>School Attending Next Fall</BootstrapForm.Label>
                    <Field
                      name="school_attending"
                      className="form-control form-control-lg"
                      />
                    <ErrorMessage
                      name="school_attending"
                      component="div"
                      className="text-danger"
                      />
                  </BootstrapForm.Group>
                </Col>
              </Row>
              )}

              {contact?.sub_type === 'child' && (
              <Row className='mt-2'>
                <Col md={6}>
                  <BootstrapForm.Group controlId="grade_point_average">
                    <BootstrapForm.Label className='required'>Grade Point Average</BootstrapForm.Label>
                    <Field
                      name="grade_point_average"
                      className="form-control form-control-lg"
                      />
                    <ErrorMessage
                      name="grade_point_average"
                      component="div"
                      className="text-danger"
                      />
                  </BootstrapForm.Group>
                </Col>
              </Row>
              )}

              <div className='d-flex gap-2'>
                <Button type="submit" className="mt-3" disabled={isSubmitting}>Update Profile</Button>
                <Button type="button" className="mt-3" variant='danger' onClick={() => navigate(-1)}>Discard</Button>
              </div>
            </Form>
          )}}
          </Formik>       
        </>
      )}

      {activeTab === 'medical' && (
        <>
          <div>Hello World</div>
        </>
      )}
  
      </>
    ) : <>Loading....</>
    }

    <ScrollArrow />
    </Container>
  );
};

export default UpdateContactMember;