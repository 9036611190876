import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Alert, Button, Form, Modal } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { axiosPrivate } from '../../axiosInstance';
import { getAllProgram, getAllTypeForm } from "../../network/api"
import {useAuth, PREV_AUTH_LOCAL_STORAGE_KEY, getAuthPromise} from '../../app/modules/auth'
import { currencyFormat } from '../../utils/funcHelpers';
import './EnrolledDashboard.scss'
import ApplicationStatusModal from '../modules/application/components/modal/ApplicationStatusModal';
import ChangeStatusModal from '../modules/application/components/modal/ChangeStatusModal';
import ResubmitRequestModal from '../modules/application/components/modal/ResubmitRequestModal';
import ApproveDenyRequestModal from '../modules/application/components/modal/ApproveDenyRequestModal';
import AddDiscountModal from './AddDiscountModal';
import EditDiscountModal from './EditDiscountModal';
import EditProgram from './EditProgram';
import ScrollArrow from './ScrollDownArrow';
import { EnrolledFormRenderer } from './EnrolledFormRenderer';

interface TypeformData {
  id: number;
  form_id: string;
  response_type: string;
  name: string;
  overview: string;
  category: string;
}

const EnrolledDashboard = ({ data, refetch }: any) => {
  const navigate = useNavigate()
  const {currentUser} = useAuth()

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showModalConfirmation, setShowModalConfirmation] = useState<boolean>(false);
  const [showModalConfirmationApproveDeny, setShowModalConfirmationApproveDeny] = useState<boolean>(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState({ success: '', error: ''});
  const [modalMessage, setModalMessage] = useState('');
  const [getAction, setAction] = useState('');

  const [selectedStatus, setSelectedStatus] = useState<string>()
  const [getCurrentStatus, setCurrentStatus] = useState<string>()
  const [isError, setIsError] = useState<string | null>(null);

  const [formData, setFormData] = useState({
    studentUUID: null,
    programUUID: null,
    formID: null,
    applicationStatus: null,
  });

  const [isRefetch, setIsRefetch] = useState<boolean>(false);

  const [hasAdmin, setHasAdmin] = useState<any>(null)

  useEffect(() => {
    const getCurrentAuth = async () => {
      const PrevAuth = await getAuthPromise(PREV_AUTH_LOCAL_STORAGE_KEY)
      if (PrevAuth) {
        setHasAdmin(true)
      } else {
        setHasAdmin(false)
      }
    }
    getCurrentAuth()
  }, []);
  
  useEffect(() => {
    //clear storage
    localStorage.removeItem('multiStepFormData');
    localStorage.removeItem('scholarshipCodes')
    localStorage.removeItem('currentStep');
    localStorage.removeItem('selectedCampus');
    sessionStorage.removeItem('selectedPaymentType');
    sessionStorage.removeItem('savedCreditCardInfo');
    sessionStorage.removeItem('savedBankAccountInfo');
    sessionStorage.removeItem('selectedStudent');
    sessionStorage.removeItem('selectedAppType');
    sessionStorage.removeItem('program_uuid');
    sessionStorage.removeItem('selectedExtras')
    sessionStorage.removeItem('tnc')
    sessionStorage.removeItem('tuition_plan')
    sessionStorage.removeItem('student_contact_id')
    sessionStorage.removeItem('typeform_data')
  }, []);

  const handleShowModal = async (student_uuid: any, program_uuid: any, form_id: any, status: string) => {
    setSelectedStatus(status);
    setCurrentStatus(status)
    setShowModal(true)
    setFormData({
      studentUUID: student_uuid,
      programUUID: program_uuid,
      formID: form_id,
      applicationStatus: null,
    });
  };

  const handleShowModalConfirmation = (student_uuid: any, application_status: any, program_uuid: any, form_id: any, message: string) => {
    setModalMessage(message);
    setShowModalConfirmation(true);
    setFormData({
      studentUUID: student_uuid,
      programUUID: program_uuid,
      formID: form_id,
      applicationStatus: application_status,
    });
  };

  const handleShowModalConfirmationApproveDeny = (student_uuid: any, program_uuid: any, form_id: any, action: string, message: string) => {
    setModalMessage(message);
    setAction(action);
    setShowModalConfirmationApproveDeny(true);
    setFormData({
      studentUUID: student_uuid,
      programUUID: program_uuid,
      formID: form_id,
      applicationStatus: null,
    });
  };

  
  const handleCloseModal = () => {
    setShowModal(false);
    setShowModalConfirmation(false);
    setShowModalConfirmationApproveDeny(false);
  };

  const handleDelete = async (program_uuid: string) => {
    if (window.confirm("Are you sure you want to delete this program?")) {
      try {
        setIsLoading(true);
        const response = await axiosPrivate.post(`/remove-application-status/${program_uuid}`);

        if (response.status === 200) {
          alert(response.data.message); 
          window.location.reload();  
        }
      } catch (error) {
        console.error('Error while deleting:', error);
      } finally {
        setIsLoading(false);
      }
    }
  };
  
  
  const handleChangeStatus = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedStatus(event.target.value);
  };

  const handleSubmitStatusChange = async (student_uuid: any, program_uuid: any, form_id: any) => {
    if (selectedStatus === getCurrentStatus) {
      setIsError(`The form status is already set to '${getCurrentStatus}'. No changes will be made.`)

      setTimeout(() => {
        setIsError('')
      }, 3000)

      return;
    }

    try {
      const response = await axiosPrivate.get('/api/typeform-updateStatus', {
        params: { student_uuid, program_uuid, form_id, selectedStatus},
      });

      if (response.data.isUpdated) {
        setIsRefetch(true)
        refetch()
      }
      
    } catch (error) {
      setIsError('An error occurred while submitting the form: ' + error)
    }

    handleCloseModal()
  };

  const handleSubmitRequest = async(student_uuid: any, application_status: any, program_uuid: any, form_id: any, message: string, account_holder_uuid?: string) => {
    try {
      setIsLoading(true);
      const data = { student_uuid, application_status, program_uuid, form_id, type: message, account_holder_uuid}
      const response = await axiosPrivate.post('/api/typeform-request', data);

      if (response.status === 200) {
        setShowSuccessMessage({ success: response.data.message, error: ''})
        setIsRefetch(true)
        refetch()
      }
      
    } catch (error: any) {
      setShowSuccessMessage({ success: '', error: 'An error occurred while submitting the form: : ' + error.message})
    } finally {
      setTimeout(() => {
        handleCloseModal()
        setShowSuccessMessage({success: '', error: ''});
        setIsLoading(false);
      }, 2000);
    }
  };

  const handleApproveAndDenyRequest = async(student_uuid: any, program_uuid: any, form_id: any, action: any, message: string) =>{
    try {
      setIsLoading(true);
      const data = { student_uuid, program_uuid, form_id, action}
      if (message === 'Approve'){
        const response = await axiosPrivate.post('/api/typeform-approveRequest', data);
        if (response.status === 200) {
          setShowSuccessMessage({ success: response.data.message, error: ''})
          setIsRefetch(true)
          refetch()
        }
      } else {
        const response = await axiosPrivate.post('/api/typeform-denyRequest', data);
        if (response.status === 200) {
          setShowSuccessMessage({ success: response.data.message, error: ''})
          setIsRefetch(true)
          refetch()
        }
      }
    } catch (error: any) {
      setShowSuccessMessage({ success: '', error: 'An error occurred while processing the request: : ' + error.message})
    } finally {
      setTimeout(() => {
        handleCloseModal()
        setShowSuccessMessage({success: '', error: ''});
        setIsLoading(false);
      }, 2000);
    }
  };


  const handlePayNow = () => {
    navigate(`/billing-and-invoice/${currentUser?.currentClient}`)
  }

  const handlePayDeposit = (program_uuid: string) => {
    navigate(`/application/${currentUser?.currentClient}/pay-deposit/${program_uuid}`)
  }

  return (
    <div style={{ fontSize: 15 }}>

      {data.map((item: any, index: number) => {
        const student_uuid = item.traveler_info.student_contacts.uuid
        const studentName = `${item.traveler_info.student_contacts.first_name} ${item.traveler_info.student_contacts.last_name}`

return (
          <div key={index}>
          {item.program.map((program: any, idx: number) => {
              if (program?.application_status === 'Removed') {
                return null;
              }
              const campusName = program?.selected_program.programData.campus
              const tuitionName = program?.student_type
              const sessionName = `${program?.selected_session?.session_name} (${moment.utc(program.selected_session.start_date).format('MM/DD/YYYY')} - ${moment.utc(program.selected_session.end_date).format('MM/DD/YYYY')})`
              const programName = program.selected_program.name
              const applicationStatusName = program?.application_status
              const latestBalance = program?.ledger_transaction ? program?.ledger_transaction[0]?.balance : 0;
              const getPayment = program?.ledger_transaction ? program?.ledger_transaction.filter((item: { transaction_type: string; }) => ['payment', 'revoked'].includes(item.transaction_type)) : [];
              const totalRefund = program?.ledger_transaction ? program?.ledger_transaction.filter((item: any) => ['refund'].includes(item.transaction_type)).reduce((sum: any, ledger: any) => sum + ledger.paid, 0) : [];
              const totalPayment = getPayment.reduce((total: number, transaction: { paid: number; }) => total + transaction.paid, 0);
              // const latestDue = latestBalance !== 0 && program?.due_schedules ? program?.due_schedules : null;  
              const totalPaidandRefund = totalPayment - totalRefund
              const getAllPayable = program?.ledger_transaction ?  program?.ledger_transaction.filter((item: { transaction_type: string; }) => !['payment', 'surcharge', 'revoked', 'discount', 'scholarship', 'removed', 'adjustment'].includes(item.transaction_type)) : [];
              const getAllDiscounts = program?.ledger_transaction ?  program?.ledger_transaction.filter((item: { transaction_type: string; }) => [ 'discount', 'scholarship'].includes(item.transaction_type)) : [];
              const overallPayment = getAllPayable.reduce((total: number, transaction: { paid: number; }) => total + transaction.paid, 0);
              const allDiscounts = getAllDiscounts.reduce((total: number, transaction: { paid: number; }) => total + Math.abs(transaction.paid), 0);
              const totalBalanceDue = (overallPayment - (totalPayment + allDiscounts));
              const latestDue = Math.max(totalBalanceDue, 0) !== 0 && program?.due_schedules ? program?.due_schedules : null;  
              const hasTPP = getAllPayable.some((item: any) => item.description === 'Tuition Protection Plan');
              const deadline = moment(program.created_at).add(14, 'days').format('MMMM DD, YYYY')

              const scholarshipTransactions = program.ledger_transaction.filter((ledger: any) => [ 'discount', 'scholarship'].includes(ledger.transaction_type));

              return (
              <div className='card card-body my-3' key={idx}>
                <div className='d-flex gap-3' style={{ alignItems: 'center' }}>
                  <div className='d-flex flex-column gap-3'>
                    <span className='fs-3 text-center'>{studentName}</span>
                    <div className='d-flex flex-column' style={{ background: "#ccc", padding: '5px' }}>
                      <span>{campusName}:</span>
                      <span className='text-capitalize'>{tuitionName} Student</span>
                      <span>{sessionName}</span>
                      <span>{programName}</span>
                    </div>
                    <div style={{ background: "#f5c310", padding: '5px'}}>Application Status: {applicationStatusName || 'Incomplete - Deposit Paid'}</div>
                    {hasAdmin && (scholarshipTransactions.length > 0) && (
                    <>
                      <tr>
                        <td style={{ fontWeight: 600 }}>
                          Scholarships and Discounts
                        </td>
                      </tr>
                      {scholarshipTransactions.map((ledger: any, index: number) => (
                        <tr key={index} className='card p-3'>
                          <div className='d-flex'>
                            <td>{ledger.description}</td>
                            <td>({currencyFormat.format(ledger.paid)})</td>
                          </div>
                          <div className='d-flex justify-content-between'>
                            <EditDiscountModal program_uuid={program.program_uuid} ledger_id={ledger.id} refetch={refetch} />
                            {/* <Button variant='danger'>Delete</Button> */}
                          </div>
                        </tr>
                      ))}
                    </>
                  )}
                  {program?.application_status === 'Incomplete - Waitlisted' || program?.application_status === 'Waitlist - Not Interested' ? null :
                    <div className="card shadow-sm mb-4">
                      <div className="card-body">
                        <h5 className="card-title">Payment Status</h5>
                        <p className="card-text">Total Paid: <strong>{currencyFormat.format(totalPaidandRefund)}</strong></p>  
                        <p className="card-text">Outstanding Balance: <strong>{currencyFormat.format(totalBalanceDue)}</strong></p>
                        {latestDue?.[0]?.balance !== undefined && latestDue?.[0]?.due_date && (
                          <p className="text-muted">Next Payment Due: {moment.utc(latestDue[0]?.due_date).format('D MMMM YYYY')} - {currencyFormat.format(latestDue[0]?.balance ?? 0)}</p>
                        )}
                      {program?.application_status === 'Incomplete - Deposit Due' ? 
                      <Button 
                        className="btn btn-primary" 
                        onClick={() => handlePayDeposit(program?.program_uuid)}>
                        Pay Deposit
                      </Button> :
                      <Button 
                        className="btn btn-primary" 
                        onClick={handlePayNow}>
                        Pay Now
                      </Button>
                      }
                        {program?.application_status === 'Incomplete - Deposit Due' ? (
                          <Button 
                            className="btn btn-danger" 
                            style={{ width: '100px', marginLeft: '15px' }} 
                            onClick={() => handleDelete(program?.program_uuid)}
                          >
                            Delete
                          </Button>
                        ) : null}
                      </div>
                    </div>
                  }
                  </div>
                  
                  <div className='flex-grow-1' style={{ alignSelf: 'flex-start'}}>
                  <table className='table table-bordered'>
                    <thead>
                      <tr>
                        <th>Section</th>
                        <th>Task Name</th>
                        <th>Overview</th>
                        <th>Status</th>
                        <th>Deadline</th>
                        <th style={{ width: 150 }}>Action</th>
                      </tr>
                    </thead>
                    <tbody style={{ border: '1px solid #dee2e6' }}>
                      <EnrolledFormRenderer
                        item={item}
                        deadline={deadline}
                        program={program}
                        handleShowModal={handleShowModal}
                        handleShowModalConfirmation={handleShowModalConfirmation}
                        handleShowModalConfirmationApproveDeny={handleShowModalConfirmationApproveDeny}
                        student_uuid={student_uuid}
                        hasAdmin={hasAdmin}
                        data={data} 
                        refetch={refetch}
                      />
                    </tbody>
                  </table>
                  <footer>
                    <div>
                      {hasAdmin && (
                        <ApplicationStatusModal
                          refetch={refetch}
                          program={program}
                        />
                      )}
                      {hasAdmin && (
                        <>
                          <AddDiscountModal
                            program_uuid={program.program_uuid}
                            refetch={refetch} 
                          />  
                        </>
                      )}
                      {hasAdmin && (
                        <>
                          <EditProgram programData={program} hasTPP={hasTPP}/>
                        </>
                      )}
                    </div>
                  </footer>
                  </div>
                </div>
              </div>
              )
          })}
          </div>
        )
      })}

      <ChangeStatusModal 
        show={showModal} 
        handleClose={handleCloseModal}
        selectedStatus={selectedStatus || ""}
        handleChangeStatus={handleChangeStatus}
        isError={isError || ""} 
        hasAdmin={hasAdmin}
        handleSubmitStatusChange={handleSubmitStatusChange}
        formData={formData}
      />

      <ResubmitRequestModal 
        show={showModalConfirmation}
        handleClose={handleCloseModal}
        modalMessage={modalMessage}
        isLoading={isLoading}
        showSuccessMessage={showSuccessMessage}
        handleSubmitRequest={handleSubmitRequest}
        formData={formData}
        currentUser={currentUser}
      />

      <ApproveDenyRequestModal 
        show={showModalConfirmationApproveDeny}
        handleClose={handleCloseModal}
        modalMessage={modalMessage}
        isLoading={isLoading}
        showSuccessMessage={showSuccessMessage}
        handleApproveAndDenyRequest={handleApproveAndDenyRequest}
        formData={formData}
        getAction={getAction}
      />

      <ScrollArrow />
    </div>
  );
};

export default EnrolledDashboard;