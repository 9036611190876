import React from 'react'
import { parse_payment } from '../../utils/funcHelpers';
import { toAbsoluteUrl } from '../../_metronic/helpers';
import { COUNTRIES_ISO_3166_ALPHA_3 } from '../../utils';
import clsx from 'clsx';
import { useAuth } from '../modules/auth';

interface PaymentMethodInputsProps {
  paymentType: string;
  selectPMethod: string;
  pMethodSaved: any;
  pMethodSavedCredit: any;
  pMethodSavedACH: any;
  pMethodSavedDebit: any;
  ccCount: number;
  ACHCount: number;
  dcCount: number;
  handlePMethodSelect: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>, setInfo: React.Dispatch<React.SetStateAction<any>>) => void;
  creditCardInfo: any;
  bankAccountInfo: any;
  setCreditCardInfo: React.Dispatch<React.SetStateAction<any>>;
  setBankAccountInfo: React.Dispatch<React.SetStateAction<any>>;
  text?: string;
  setPrimaryPayment?: any
  setItemPMethod?: any
  handlePrimaryMethodSelect?: any
}

const PaymentMethodInputsV3: React.FC<PaymentMethodInputsProps> = ({
  paymentType,
  pMethodSaved,
  pMethodSavedCredit,
  pMethodSavedACH,
  pMethodSavedDebit,
  ccCount,
  ACHCount,
  dcCount,
  handlePMethodSelect,
  text,
  setItemPMethod,
  handlePrimaryMethodSelect,
  setPrimaryPayment
}) => {

  const { currentUser } = useAuth()

  if (paymentType === 'creditCard' || paymentType === 'debitCard') {

    const isCreditCard = paymentType === 'creditCard';
    const count = isCreditCard ? ccCount : dcCount;
    const pMethodSavedData = isCreditCard ? pMethodSavedCredit : pMethodSavedDebit;

    return (
      <>
        {pMethodSaved && pMethodSaved.map((data: any) => { return data.paymentType  === paymentType})  && count > 0 ? (
        <>
        <div className='d-flex flex-column' >
          {pMethodSavedData?.map((data: any, index: number) => {
            const dataSaveText = data ? parse_payment(data) : null
              return (
                <div key={data?.id} className='card p-2 mb-7 me-5 w-100'>
                  <div className='d-flex align-items-center justify-content-between gap-2'>
                    <label
                      className='form-check-label'
                      htmlFor={`funding_${data?.id}`}
                    >
                      {dataSaveText?.img && (
                        <img
                          src={toAbsoluteUrl(dataSaveText?.img)}
                          alt={dataSaveText.value}
                          className="h-25px"
                        />
                      )}
                      {dataSaveText?.value}{' '}
                      xx{data?.cardNumber?.replaceAll('x', '')}
                    </label>
                    <div className='d-flex align-items-center'>
                      <input
                        type='radio'
                        className='form-check-input'
                        value={`funding_${data?.id}`}
                        name={'selectPMethod'}
                        id={`funding_${data?.id}`}
                        onChange={(e) => handlePrimaryMethodSelect(e, data)}
                      />
                      <button
                        type='button'
                        className='btn btn-sm btn-outline-danger'
                        onClick={() => setItemPMethod({modal: 'deletePMethod', item: data, uuid: currentUser?.uuid || ''})}
                      >
                        <i className='fa-solid fa-trash p-0'></i>
                      </button>
                    </div>
                  </div>
                </div>
            )})}
            <div className='card card-body me-5 w-100'>
              <div className='d-flex justify-content-between'>
                <label className='form-check-label' htmlFor={paymentType}>
                  {text ? 'Add New Card' : 'Use a new payment method'}
                </label>
                <input
                  type='radio'
                  className='form-check-input me-2'
                  value={paymentType}
                  name={'selectPMethod'}
                  id={'creditCard_selectPMethod'}
                  onChange={handlePMethodSelect}
                />
              </div>
            </div>
          </div>
        </> 
      ) : (
        <div>
          <div className='card card-body me-5 w-100'>
            <div className='d-flex justify-content-between'>
              <label className='form-check-label' htmlFor={paymentType}>
                {text ? 'Add New Card' : 'Use a new payment method'}
              </label>
              <input
                type='radio'
                className='form-check-input me-2'
                value={paymentType}
                name={'selectPMethod'}
                id={'creditCard_selectPMethod'}
                onChange={handlePMethodSelect}
              />
            </div>
          </div>
        </div>
        )}
      </>
    )
  } else if (paymentType === 'bankAccount') {
    return (
      <>
      {pMethodSaved && pMethodSaved.map((data: any) => { return data.paymentType  === 'bankAccount'}) && ACHCount > 0 ? (
        <>
          {pMethodSavedACH?.map((data: any, index: number) => {
            const dataSaveText = data ? parse_payment(data) : null
            return (
                <div key={index} className='card p-2 mb-7 me-5 w-100'>
                <div className='d-flex align-items-center justify-content-between gap-3'>
                  <label
                    className='form-check-label'
                    htmlFor={`funding_${data?.id}`}
                  >
                    {dataSaveText?.img && (
                      <img
                        src={toAbsoluteUrl(dataSaveText?.img)}
                        alt={dataSaveText.value}
                        className="h-25px"
                      />
                    )}
                    {dataSaveText?.value}{' '}
                    Ends {data?.cardNumber?.replaceAll('x', '')}
                  </label>

                  <div className='d-flex align-items-center gap-2'>
                    <input
                      type='radio'
                      className='form-check-input'
                      value={`funding_${data?.id}`}
                      name={'selectPMethod'}
                      id={`funding_${data?.id}`}
                      onChange={(e) => handlePrimaryMethodSelect(e, data)}
                    />

                    <button
                      type='button'
                      className='btn btn-sm btn-outline-danger'
                      onClick={() => setItemPMethod({modal: 'deletePMethod', item: data, uuid: currentUser?.uuid || ''})}
                    >
                      <i className='fa-solid fa-trash p-0'></i>
                    </button>
                  </div>
                </div>
              </div>
            ) 
          })}
           <div className='card card-body me-5 w-100'>
            <div className='d-flex justify-content-between'>
            <label
              className='form-check-label'
              htmlFor={'bankAccount_selectPMethod'}
              >
                {text ? 'Add New Bank Account' : 'Use a new payment method'}
            </label>
            <input
              type='radio'
              className='form-check-input me-2'
              value={'bankAccount'}
              name={'selectPMethod'}
              onChange={handlePMethodSelect}
              />
            </div>
          </div>
        </>
      ) : (
      <>
        <div className='card card-body me-5 w-100'>
          <div className='d-flex justify-content-between'>
          <label
            className='form-check-label'
            htmlFor={'bankAccount_selectPMethod'}
            >
              {text ? 'Add New Card' : 'Use a new payment method'}
          </label>
          <input
            type='radio'
            className='form-check-input me-2'
            value={'bankAccount'}
            name={'selectPMethod'}
            onChange={handlePMethodSelect}
            />
          </div>
        </div>
      </>
      )}
    </>    
    )
  } else {
    return null;
  }
};

export default PaymentMethodInputsV3;