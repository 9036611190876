import {Routes, Route, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import {useAuth} from '../modules/auth'
import TerraRoute from './TerraRoute'
const PrivateRoutes = () => {
  const {currentUser, currentLocalDomain} = useAuth()
  const RouterPages = () => {
    if (currentLocalDomain === 'portal.terraed.com' && currentUser?.currentClient)
      return <Route path='/*' element={<TerraRoute />} />
    else return ''
  }
  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after succes login/register */}
        {currentUser?.role === 'superadmin' ? (
          <>
            <Route
              path='auth/*'
              element={<Navigate to={`/dashboard/admin/${currentUser?.currentClient}`} />}
            />
            <Route
              path='dashboard/admin/'
              element={<Navigate to={`/dashboard/admin/${currentUser?.currentClient}`} />}
            />
          </>
        ) : (
          <>
            <Route
              path='auth/*'
              element={<Navigate to={`/dashboard/${currentUser?.currentClient}`} />}
            />
            <Route
                path='dashboard/'
                element={<Navigate to={`/dashboard/${currentUser?.currentClient}`} />}
            />
          </>
        )}
        {/* Pages */}
        {RouterPages()}
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}
export {PrivateRoutes}
